angular.module('fingerink')
    .directive('rowDocumentoEnviado', function () {
        return {
            restrict: 'AEC',
            replace: true,
            templateUrl: 'web/main/documents/directive/documentsRow.tpl.html',
            scope: {
                firma: '=',
                categories: '=',
                categoriesmap: '=',
                update: '&'
            },
            controllerAs: 'controller',
            controller: 'rowDocumentoEnviadoCtrl'
        };
    })
    .controller('rowDocumentoEnviadoCtrl', function ($rootScope, $scope, signatureService, $uibModal, $translate, swalService, viewSignatureModal) {
        var that = this;
        $scope.labels = ['Finalizado', 'Pendiente'];

        that.firma = $scope.firma;
        that.categories = $scope.categories;
        that.estadoColor = [];
        that.estadoColor['PENDING'] = '#0275d8';
        that.estadoColor['REFUSED'] = '#d80202';
        that.estadoColor['WAITING'] = '#d88e02';
        that.estadoColor['COMPLETED'] = '#5cb85c';


        that.preview = ()=> viewSignatureModal.openModal(that.firma);

        if(that.firma.type=='SelfSigning'){
            that.signersText = $translate.instant("SelfSigning");
        }
        that.documentSigners = that.firma.signers.filter(s=>s.signType != 'DOCUMENT' && s.completed !='SIGNERDELETED');
        if(that.documentSigners.length == 1){
            that.signersText = that.documentSigners[0].name + " ("+ that.documentSigners[0].signatureEmail + ")";
        }else if(that.documentSigners.length > 1){
            that.signersText = that.documentSigners.length +" " + $translate.instant("Firmantes");
        }
        //that.signersText = () => that.firma.signers.find(s=>s.signType != 'DOCUMENT' && s.completed !='SIGNERDELETED');

        signatureService.getURL(that.firma.id, 0).then((response) => that.urlImage = response.data);  
        
        let downloadLink = (link, name) => angular.element('<a></a>').attr('href', link).attr('download', name)[0].click();
        that.signed = () => signatureService.getSignedURL(that.firma.id).then(response => downloadLink(response.data, 'signed.pdf'));

        that.changeCategory = (category)=> {
            that.firma.category = category;
            signatureService.moveFirmatoCategory(that.firma);
        };

        that.delete = ()=> swalService.requestSwal('¿Estás seguro?','Se va a eliminar la firma', 'warning',()=>signatureService.deleteSignature(that.firma.id))
        .then(()=>{
            $scope.update();
            swalService.basicSwal('¡Correcto!','La firma se ha eliminado correctamente','success');
        });

    });
